.tbody--fixed {
    display: block;
    max-height: 500px;
    overflow-y: auto;
}

.thead--fixed,
.tbody--fixed tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.thead--fixed {
    width: calc(100% - 1em)
}

.table--fixed {
    width: 400px;
}

@media (max-width: 1000px) {
    .min--width {
        width: 100px;
    }
}