.historial-container {
    margin: 1rem auto;
    max-width: 1000px;
    overflow: hidden;
}

@keyframes inAnimation {
    0% {
        height: 0;
        opacity: 0;
        visibility: hidden;
    }
    100% {
        height: auto;
        opacity: 1;
        visibility: visible;
    }
}

@keyframes outAnimation {
    0% {
        height: auto;
        opacity: 1;
    }
    100% {
        height: 0;
        opacity: 0;
        visibility: hidden;
    }
}

.historial-header {
    display: none;
}

.historial-body {
    border-radius: 10px;
    overflow: hidden;
}

.historial-body tbody tr td {
    padding: 0.5rem;
    border: none;
    background-color: #e5e5e5;
    color: #959596;
}
