.bg--primary {
    background-color: #6BA2FD !important;
}

.table .thead-primary th {
    color: black;
    background-color: #6BA2FD;
    border-color: #6BA2FD;
}

.table td {
    border: 1px solid black;
    background-color: white;
}

.table {
    color: black !important;
}

h5, h4, h3 {
    color: black;
}

.text--strong {
    font-weight: bold;
}

.tbody--fixed--1 {
    display: block;
    max-height: 600px;
    overflow: auto;
}

.thead--fixed--1,
.tbody--fixed--1 tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.thead--fixed--1 {
    width: calc(100% - 1em)
}

.table--fixed--1 {
    width: 100%;
}

.chart-container {
    position: relative;
    margin: auto;
    height: 80%;
    width: 80%;
}

.recurso--card {
    width: 70%;
}

@media (max-width: 767px) {
    .recurso--card {
        width: 100%;
    }   

    .chart-container {
        position: relative;
        margin: auto;
        height: 100%;
        width: 100%;
    }
}

