/* tablaSkills.css */

.table-skills {
    width: 100%;
    border-collapse: collapse;
}

.table-skills th,
.table-skills td {
    border: 1px solid #007bff;
    padding: 8px;
    text-align: left;
}

.table-thead th {
    background-color: #233E6F;
    color: #f2f2f2;
}


.duplicate-skill {
    color: white; /* Color de texto blanco */
    background-color: rgb(195, 10, 10);
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    position: relative;
    animation: borderAnimation 5s linear infinite;
}

.normal-skill {
    background-color: white;
    color:#233E6F;
}


@keyframes borderAnimation {
    0% {
        box-shadow: inset 0px 0px 0px 0px rgb(195, 10, 10);
    }
    100% {
        box-shadow: inset 800px 0px 0px 0px rgb(242, 3, 3);
    }
}
