.modal-app {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 0; /* Remove gap between inline-block elements */
    background: rgba(32, 35, 41, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 110000;
}

.modal--content {
    width: 70%;
    max-height: 90vh;
    overflow-y: scroll;
    background-color: white;
    padding-top: 50px;
    border-radius: 10px;
}

.modal--content .text-info {
    padding-left: 100px;
}

.modal--content h3 {
    margin: 0;
}

.modal-asuetos--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-asuetos--header .btn-asuetos {
    font-size: 15px;
    display: flex;
    align-items: center;
}

.modal-asuetos--header .btn-asuetos p {
    margin: 0;
}
