.display--fieldset {
    display: flex;
    flex-direction: row;
    color: white;
    background-color: #D9E2F4;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    border-radius: 15px;
    height: 48px;
    width: 100%;
}

/* The container */
.container--1 {
    display: inline-block;
    position: relative;
    cursor: pointer;
    /* width: 33%; */
    height: 40px;
    margin: 0 3px;
    padding: 0 8px;
    line-height: 40px;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 15px;
    text-align: center;
    transition: background-color 0.1s ease-in;
}

.container--1:has(input:checked) {
    background-color: #233E6F;
    color: white;
}

/* Hide the browser's default checkbox */
.container--1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container--1:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container--1 input:checked~.checkmark {
    background-color: #1F4D7B;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container--1 input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container--1 .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Style for progress bar */
.step-progress-bar p {
    font-size: 18px;
    margin-bottom: 5px;
    color: rgb(7,121,251);
    font-weight: 500;
}

.step-progress-bar p span{
    font-weight: 700;
}

.progress-bar-form {
    width: 90%;
    height: 7px;
    background-color: rgb(217,217,217);
    margin-bottom: 50px;
}

.progress-bar-form div {
    width: 25%;
    height: 100%;
    background-color: rgb(7,121,251);
  }

.hidden-form-input {
    display: none;
}
