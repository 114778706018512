/* Alinea la columna de acciones a la derecha y evita saltos de línea */
.actions-column {
  text-align: right;
  white-space: nowrap;
}

/* Quita bordes */
.table-borderless tbody tr td,
.table-borderless thead tr th {
  border: 0 !important;
}

/* Para que nada se quiebre al usar la tabla responsive */
.content--table td,
.content--table th {
  vertical-align: middle;
  /* También puedes forzar un ancho mínimo si necesitas:
     min-width: 100px;
  */
}

/* Botón de toggle (+ / -) para expandir/contraer */
.toggle-btn {
  color: #007bff;
  font-size: 20px;
  text-decoration: none;
  padding: 0;
}

/* Drag column: centrada y sin quiebres */
.drag-column {
  text-align: center;
  white-space: nowrap;
}

/* Íconos de edición y borrado, etc. */
.standard-action i.material-icons {
  font-size: 20px;
}

/* Espaciado horizontal entre íconos y botones */
.standard-action + .standard-action {
  margin-left: 8px;
}
.standard-action + .btn,
.btn + .standard-action {
  margin-left: 10px;
}
