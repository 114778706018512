.title--blue {
    margin-top: 20px;
    height: 42px;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    color: #1F4D7B;
}

.title--blue--1 {
    color: #1F4D7B !important;
    font-weight: bold;
}

.text--blue--1 {
    color: #1F4D7B !important;
}

label,
select {
    color: #1F4D7B;
}

.input--style {
    color: #1F4D7B !important;
    border-color: #1F4D7B !important;
}

.button--primary {
    color: #1F4D7B !important;
    border-color: #1F4D7B !important;
}

.card--header {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #7D8592;
    /* color: white !important; */
    /* background-color: #1F4D7B !important */
}

.card--header--1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #1F4D7B !important;
    background-color: rgba(31, 77, 123, 0.3) !important
}

.border--lr td {
    border-left: 1px solid #979797 !important;
    border-right: 1px solid #979797 !important;
}

.border--bt th {
    border-bottom: 1px solid #979797 !important;
}

.border--last td {
    border-bottom: 1px solid #979797 !important;
}

.border-l {
    border-left: 1px solid #979797 !important;
}

.border-r {
    border-right: 1px solid #979797 !important;
}

.all-borders {
    border: 1px solid #979797;
}

.tabla-resumen::-webkit-scrollbar {
    height: 7px;
}

.tabla-resumen::-webkit-scrollbar-thumb {
    background-color: #1F4D7B;
    border-radius: 5px;
}

.nav--link--color {
    color: #1F4D7B !important;
}

.table .thead--blue th {
    color: #fff;
    background-color: #1F4D7B;
    border-color: #979797;
}

.table .tr--red td {
    color: #fff;
    background-color: #EC6D51;
    border-color: #979797;
}

.thead--blue--individual {
    color: #fff;
    background-color: #1F4D7B;
    border-color: #979797;
}

.resources--table tbody {
    color: #1F4D7B;
}

.td--individual {
    color: #1F4D7B;
}

.add--button {
    background-color: #233E6F !important;
    color: white !important;
}

.content--table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 400px;
    width: 100%;
    /* border-radius: 15px 15px 0 0; */
    overflow: hidden;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

.content--table thead tr {
    /* background-color: #1F4D7B; */
    color: #7D8592;
    text-align: left;
    font-size: 14px;
}

.content--table th {
    padding: 12px 15px;
    /* border-right: 1px solid #D1D1D1; */
}

.content--table td {
    padding: 5px 10px;
    /* border-right: 1px solid #D1D1D1; */
}

.content--table tbody {
    background-color: #fff;
}

.content--table tbody tr {
    /* border-bottom: 1px solid #D1D1D1; */
    color: #1F4D7B;
    font-size: 12px;
}

.content--table tbody tr:last-of-type {
    border-bottom: 2px solid #1F4D7B;
}

.content--table--1 table:first-child {
    border-collapse: collapse;
    font-size: 0.9em;
    min-width: 400px;
    width: 100%;
    /* border-radius: 15px 15px 0 0; */
    overflow: hidden;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

.content--table--1 thead tr {
    color: #7D8592;
    text-align: left;
    font-size: 14px;
}

.content--table--1 tbody tr {
    /* border-bottom: 1px solid #D1D1D1; */
    border-bottom-width: 0;
    color: #1F4D7B;
    font-size: 15px;
}

.content--table--1 tbody tr:last-of-type {
    border-bottom: 2px solid #1F4D7B;
}

/* React table bootstrap */
.table--header thead th, .table--header thead tr {
    border: hidden;
}

.table--body tbody td {
    border-right: hidden;
    border-left: hidden;
}

.table--body {
    border: hidden;
    border-radius: 5px;
}

/* Actions */
.standard-action {
    width: 32px;
    height: 32px;
    margin: 0 3px;
    padding: 2px;
    border-radius: 5px;
    text-align: center;
}

.action--edit {
    color: #233E6F;
    background-color: #D9E2F4;
}

.action--delete {
    color: rgb(246, 81, 96);
    background-color: rgba(246, 81, 96, 0.3);
}

.action--end {
    color: rgb(255,181,59);
    background-color: rgba(255,181,59, 0.3);
}

.action--resources {
    color: rgb(10,184,217);
    background-color: rgba(10,184,217, 0.3);
}

/* Modal form */
.modal-form-overlay {
    z-index: 110000 !important;
}

.modal-form-content {
    border-radius: 5px;
    padding: 20px;
    width: 40%;
    max-width: 600px;
    min-width: 400px;
}

/* Field array */
.field--array {
    padding: 5px;
    border: 1px solid rgba(206, 213, 224, 1);
    margin: -1px 0 0 -1px;
}

.field--array::before{
    content: ' ';
    height: inherit;
    width: 3px;
    border-radius: 5px;
    transition: background-color 0.05s ease-in;
}

.field--array input {
    border: 0px;
}

.field--array input:focus, .field--array input:hover {
    border-width: 1px;
    border-style: solid;
}

.field--array:hover::before{
    background-color: #233E6F;
}

/* Card */
.card-form {
    border: 0;
    border-radius: 1rem;
    padding: 2rem;
    background-color: white;
}

/* Proyecto operaciones */
.project-summary {
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem;
}

.project-summary h5{
    margin: 0;
    font-size: 16px;
}

.project-summary hr{
    margin-top: 2px;
}

.project-summary h5, .project-summary hr{
    color: #7D8592;
}

.project-summary--row {
    padding: 5px;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(206, 213, 224, 1);
}

.project-summary--row.highlight {
    color: #1F4D7B;
}

.project-summary--row.highlight h6{
    font-weight: bold;
}

.project-summary--row h6 {
    margin: 0;
    margin-right: 0.5rem;
    width: 50%;
}

.project-summary--row p {
    margin: 0;
    font-weight: bold;
}

/* Progressbar */
.progressbar {
    padding: 0;
    margin: 0;
    position: fixed;
}
.progressbar li {
	position: relative;
	width: 15px;
	height: 15px;
	margin: 0 auto 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
	border-radius: 50%;
	background: #CED5E0;
}

.progressbar li:before{
    content: '';
    display: block;
    width: 50%;
    height: 50%;
    border-radius: 50%;
}
/*progressbar connectors*/
.progressbar li:after {
	content: '';
	width: 2px;
	height: 65px;
	background: #CED5E0;
	position: absolute;
    left: calc(50% - 1px);
	top: calc(-100% - 40px);
	z-index: -1;
}
.progressbar li:first-child:after {
	/*connector not needed before the first step*/
	content: none;
}

.progressbar li.active, .progressbar li > button.active {
	background: white;
    border: 1px solid #4070CA;
}

.progressbar li.active:after{
	background: #4070CA;
}

.progressbar li.active:before {
    background: #4070CA;
}

.progressbar li > button {
    position: absolute;
    left: 100%;
    width: max-content;
    margin-left: 5px;
    padding: 8px;
    display: inline;
    border: 1px solid;
    border-radius: 15px;
    background: #CED5E0;
    color: #4070CA;
    font-size: 14px;
}


/* Recursos preview */
[role="tooltip"].popup-content {
    width: fit-content;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.recurso-preview-overlay {
    z-index: 110000 !important;
}

.recurso-preview-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    max-height: 90vh;
    overflow-y: scroll;
}

.recurso-preview-content img {
    border-radius: 10%;
}

.recurso-preview-content ul.info-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.recurso-preview-content ul.files-list {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

/* Recurso reportes */
.card--reportes {
    width: 100%;
    max-width: 1050px;
    box-shadow: "0 0 20px rgba(0, 0, 0, 0.15)";
    overflow: hidden;
}

.card--reportes .header-info {
    padding: 21px 32px;
    font-size: 20px;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    background-color: #1F4D7B;
}

.card--reportes .chart-details {
    line-height: 150%;
    color: #717384;
    padding: 30px 0 0 32px;
}

.card--reportes .chart-details .total-title {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
}

.card--reportes .chart-details .update-info {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}

.card--reportes .card-body .list-information {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    font-size: 1rem;
    font-weight: bold;
}

.card--reportes .card-body .list-information .label-information{
    margin-right: 8px;
}

.card--reportes .card-body .list-information .label-totals{
    color: #717384;
}

.card--reportes .card-body .list-colaboradores {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    list-style: none;
    font-size: 1rem;
}

/* Table colaboradores permisos */
.content-table-permisos .table--header {
    border-collapse: collapse;
    font-size: 0.9em;
    min-width: 400px;
    width: 100%;
    /* border-radius: 15px 15px 0 0; */
    overflow: hidden;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

.content-table-permisos .table--header {
    margin-bottom: .5rem;
}

.content-table-permisos .table--header thead th {
    color: #3D5170;
    font-size: 1.125rem;
    text-align: center;
}

.content-table-permisos .table--body tbody tr td {
    padding: 1rem 0;
    font-size: 1rem;
    color: #9D9FA1;

}

.content-table-permisos .table--body tbody tr {
    cursor: pointer;
}


/* Styles for disabled inputs solicitud */
.form-disable .input--style:disabled,
.form-disable .input--style:read-only,
.form-disable textarea:disabled
{
    background-color: white;
    border: none;
    color: #959596 !important;
}

/* Skills solicitud personal styles */
.solicitud-personal--skill {
    max-height: 40px;
    padding: 5px;
    margin: .3rem .8rem;
    background-color: #F2F2F2;
    color: #959596;
    border-radius: 6px;
    font-size: .7rem;
}

.solicitud-personal--skill i {
    color: #959596;
    font-size: 1rem;
}


/* Modal form for Recursos */
.modal-form-recursos-overlay {
    z-index: 110000 !important;
}

.modal-form-recursos-content {
    border-radius: 5px;
    padding: 20px;
    width: 70%;
    min-width: 800px;
    overflow: scroll;
    max-height: 80vh;
}

/* Table row style */
.content--table--1 table tr.tr-table {
    border-top: 6px solid #FFF;
    border-bottom: 6px solid #FFF;
}

.content--table--1 table tbody tr.tr-table td {
    border-top: 0px;
    border-bottom: 0px;
    border-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #959596;
    background-color: #F6F6F6;
}

.content--table--1 table tbody tr.tr-table td:first-child {
    position: relative;
    border-left: 10px solid white;
}

.content--table--1 table tbody tr.tr-table td:last-child  {
    position: relative;
    overflow: visible;
    border-right: 20px solid white;
}

.content--table--1 table tbody tr.tr-table td:first-child::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 10px;
    left: 0;
    bottom: 0;
    border-radius: 10px 0 0 10px;
}

.content--table--1 table tbody tr.tr-table td:last-child::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 15px;
    right: -10px;
    bottom: 0;
    border-radius: 20px;
    background-color: #F6F6F6;
}

.content--table--1 table tbody tr.tr-table.tr-odd td:first-child::before {
    background-color: #CA0000;
}

.content--table--1 table tbody tr.tr-table.tr-even td:first-child::before {
    background-color: #0DA140;
}

._19jgW::-webkit-scrollbar {
    height: 7px;
}

._19jgW::-webkit-scrollbar-thumb {
    background-color: #1F4D7B;
    border-radius: 5px;
}
