.historial-container {
    margin: 20px;
}

.historial-table {
    width: 100%;
    border-collapse: collapse;
}

.historial-table th, .historial-table td {
    padding: 10px;
    border: 1px solid #b3d9ff; 
}

.historial-table th {
    background-color: #d9edff; 
}

.historial-table tbody tr:nth-child(even) {
    background-color: #e6f3ff; 
}

.historial-table tbody tr:hover {
    background-color: #cceeff; /* Cambia el color de fondo al pasar el cursor */
}