.table--scroll {
    overflow-x: auto;
    position: relative;
}

.position--thtd {
    position: sticky;
}

@media (max-width: 900px) {
    .position--thtd {
        position: unset;
    }
}

@media (max-width: 675px) {
    .popup-content {
        margin: auto;
        background: #fff;
        width: 90%;
        padding: 5px;
        border: 1px solid #d7d7d7;
    }
}