.table--scroll {
    overflow-x: auto;
    position: relative;
}

.position_table--thtd {
    position: sticky !important;
    left: 0;
    min-width: 42px !important;
    /* max-width: 40px !important; */
    /* padding-left: 5px !important;
    padding-right: 5px  !important; */
    outline: 1px solid #e4e4e4 !important;
    outline-offset: 0px !important;
    z-index: 100;
    background-color: #fff;
}

.position_table--thtd--2 {
    position: sticky !important;
    left: 42px;
    min-width: 260px !important;
    outline: 1px solid #e4e4e4 !important;
    outline-offset: 0px !important;
    z-index: 100;
    background-color: #fff;
}

.position_table--thtd--3 {
    position: sticky !important;
    left: calc(42px + 260px);
    min-width: 260px !important;
    outline: 1px solid #e4e4e4 !important;
    outline-offset: 0px !important;
    z-index: 100;
    background-color: #fff;
}

.input__reporte, .input__reporte:focus {
    background-color: #fbfbfb !important;
}
.input__reporte::placeholder {
    color: #1f4d7bda !important;
    background-color: #fbfbfb !important;
}
/* .position_table--thtd--3 {
    position: relative !important;
    left: 310px;
} */
.text--blue {
    color: #1f4d7b !important;
}
.text--grey {
    font-weight: normal !important;
    color: #5a6169 !important;
}
.text--blueBold {
    font-weight: bold;
    color: #1f4d7b !important;
}
.bg--greySoft {
    background: #e9e8e8 !important;
}

/* .popup-content {
    margin: auto;
    background: rgba(255, 255, 255, 0);
    width: 50%;
    padding: 5px;
    border: 1px solid #ffffff00;
} */

@media (max-width: 900px) {
    .position--thtd {
        position: unset;
    }
    .position_table--thtd {
        position: unset;
    }
    .position_table--thtd--2 {
        position: unset;
    }
}

@media (max-width: 765px) {
    .button--widthFull {
        width: 100%;
    }
}

@media (max-width: 675px) {
    .popup-content {
        margin: auto;
        background: rgba(255, 255, 255, 0);
        width: 90%;
        padding: 5px;
        border: 1px solid #ffffff00;
    }
}
